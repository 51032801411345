<template>
  <v-row no-gutters>
    <v-col cols="12" sm="6" md="5">
      <p class="custom-normal-text">Possui cupom?</p>
      <v-text-field
        label="Cupom"
        persistent-hint
        hint="Todas as letras devem ser maiúsculas"
        v-model="coupon"
        :disabled="loading"
        @input="clear()"
      ></v-text-field>
      <!-- <p class="caption grey--text text--lighten-1" >Obs: Só pode se aplicar um único cupom e deve estar maiúscula</p> -->
      <v-alert type="error" outlined :value="error">
        {{ errorMessage }}
      </v-alert>
      <v-btn
        small
        class="mx-0 custom-btn-blue rounded-0"
        @click="applyCoupon()"
        :loading="loading"
      >
        aplicar
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { paymentMethods } from '@state/helpers'
export default {
  data() {
    return {
      coupon: '',
      loading: false,
      errorMessage: '',
      error: false,
    }
  },
  methods: {
    ...paymentMethods,

    clear() {
      this.loading = false
      this.error = false
      this.errorMessage = ''
    },
    applyCoupon() {
      this.clear()
      this.loading = true
      this.applyDiscount(this.coupon)
        .then(() => {
          this.clear()
          this.coupon = ''
        })
        .catch((error) => {
          this.loading = false
          this.error = true
          this.errorMessage = 'Cupom não é valido'
        })
    },
  },
}
</script>

<style module lang="scss">
@import '@design';
</style>
