<template>
  <v-row justify="center">
    <v-btn
      large
      class="custom-btn-green rounded-0"
      @click="goToCall()"
      :loading="loading"
      >prosseguir para agendar o call</v-btn
    >
    <v-col cols="12" class="text-center">
      <v-alert type="error" :value="error">
        O seu plano não permite esta ação.<br />
        Entre encontato com nossa equipe.
      </v-alert>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      error: false,
      loading: false,
    }
  },
  methods: {
    async goToCall() {
      try {
        this.loading = true
        await this.$store.dispatch('paymentInfo/nextStepFreePlan')
        this.$router.push({ path: 'additional-info-form' })
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
        this.error = true
      }
    },
  },
}
</script>
<style module lang="scss">
@import '@design';
</style>
