var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-col',{staticClass:"text-center text-body-2 grey--text text--lighten-1 mt-0 pt-0",attrs:{"cols":"12"}},[_vm._v(" compra segura "),_c('v-icon',{attrs:{"small":"","color":"grey lighten-1"}},[_vm._v("lock")])],1),_c('v-col',{staticClass:"custom-normal-text text-green font-weight-bold px-0",attrs:{"cols":"12"}},[_vm._v(" Formas de pagamento ")]),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{class:[
          'rounded-0',
          _vm.btnActive.bankSlip ? 'custom-btn-blue' : 'custom-btn-blue-inverse',
        ],attrs:{"block":""},on:{"click":function($event){;(_vm.btnActive.bankSlip = true), (_vm.btnActive.creditCard = false)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$vuetify.icons.bankSlip")]),_vm._v(" boleto/pix ")],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{class:[
          'rounded-0',
          _vm.btnActive.creditCard
            ? 'custom-btn-blue'
            : 'custom-btn-blue-inverse',
        ],attrs:{"block":""},on:{"click":function($event){;(_vm.btnActive.bankSlip = false), (_vm.btnActive.creditCard = true)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("credit_card")]),_vm._v(" cartão de crédito ")],1)],1)],1),(_vm.btnActive.bankSlip)?_c('bank-slip',{attrs:{"transition":"slide-x-reverse-transition"}}):_vm._e(),(_vm.btnActive.creditCard)?_c('credit-card',{attrs:{"transition":"slide-x-reverse-transition"}}):_vm._e(),_c('v-alert',{attrs:{"type":"error","outlined":"","value":_vm.paymentError}},[_vm._v(" "+_vm._s(_vm.paymentErrorMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }