<template>
  <div>
    <span v-for="item in icons" :key="item.name" class="mr-2">
      <v-icon
        medium
        :color="item.active === active ? 'primary' : ''"
        v-text="item.name"
      ></v-icon>
    </span>
  </div>
</template>

<script>
export default {
  name: 'icons-card',
  props: {
    active: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      icons: [
        {
          name: 'fab fa-cc-mastercard',
          active: 'mastercard',
        },
        {
          name: 'fab fa-cc-visa',
          active: 'visa',
        },
        {
          name: 'fab fa-cc-amex',
          active: 'amex',
        },
        {
          name: 'fab fa-cc-diners-club',
          active: 'diners',
        },
        {
          name: '$vuetify.icons.elo',
          active: 'elo',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped></style>
