<template>
  <v-row no-gutters justify="space-around">
    <v-col cols="12" sm="6" md="5">
      <p class="custom-normal-text text-green font-weight-bold">
        Resumo da contratação
      </p>
      <p v-if="loading">
        <v-icon>fas fa-circle-notch fa-spin</v-icon>
        <v-divider></v-divider>
      </p>
      <v-row
        v-else
        v-for="(item, key) in firstPaymentSummary.items"
        :key="key"
        class="py-2 custom-normal-text"
      >
        <v-col cols="7">
          {{ item.name }} <br /><a
            class="custom-text-link"
            v-if="item.isPlan"
            @click="cahngePlan()"
            >(Alterar Plano)</a
          >
        </v-col>
        <v-col class="text-right" cols="5">
          {{ item.value | currencyFromCents }}
        </v-col>

        <v-col class="my-1" cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-col cols="12" class="text-right">
        <strong>
          Total
          <v-icon v-if="loading">fas fa-circle-notch fa-spin</v-icon>
          <span v-else>
            {{ firstPaymentSummary.total | currencyFromCents }}
          </span>
        </strong>
      </v-col>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="6"
      :class="[this.$vuetify.breakpoint.smAndUp ? 'pl-5' : '']"
    >
      <p class="custom-normal-text text-green font-weight-bold">Responsável</p>
      <div class="custom-normal-text font-weight-bold py-1">
        {{ companyPaymentInfo.name }}
      </div>
      <div class="custom-normal-text">{{ address }}</div>
      <div class="custom-normal-text">
        {{ companyPaymentInfo.address.city }} -
        {{ companyPaymentInfo.address.state }}
      </div>
      <div class="custom-normal-text">{{
        companyPaymentInfo.address.cep | toCEP
      }}</div>
      <a class="custom-text-link" @click="updatePaymentForm">(Alterar)</a>
    </v-col>
  </v-row>
</template>

<script>
import { paymentComputed, paymentMethods } from '@state/helpers'
export default {
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...paymentComputed,
    address() {
      let text = `${this.companyPaymentInfo.address.address}, ${this.companyPaymentInfo.address.addressNumber}`
      text += !!this.companyPaymentInfo.address.addressComplement
        ? `, ${this.companyPaymentInfo.address.addressComplement}`
        : ''
      return text
    },
  },
  methods: {
    ...paymentMethods,
    async cahngePlan() {
      await this.$store.dispatch('auth/updateUser', { idStepRegistration: 2 })
      this.$router.push({ name: 'resume' })
    },
    async updatePaymentForm() {
      await this.saveCompanyPaymentInfoField({ step: 1 })
    },
  },
  watch: {
    firstPaymentSummary(newValue, oldValue) {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 1500)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@design';
a {
  color: #0d5f18;
  text-decoration: none;
}
</style>
