<template>
  <v-row no-gutters class="">
    <v-col
      cols="12"
      sm="9"
      style="text-align: justify; text-justify: inter-word"
      class="custom-normal-text grey--text text--darken-1"
    >
      Imprima o boleto e pague no banco ou pague pela internet utilizando o
      código de barras do boleto o prazo de validade do boleto é de 1 dia útil
      <br />
    </v-col>
    <v-col cols="12" sm="4" class="py-12">
      <div class="mb-3">
        <strong>
          Total:
          {{ firstPaymentSummary.total | currencyFromCents }}
        </strong>
      </div>
      <v-btn
        large
        class="ml-0 custom-btn-green rounded-0"
        block
        @click="paymentCreat"
        :loading="companyPaymentInfoLoadingCreateWithBoleto"
        :readonly="companyPaymentInfoLoadingCreate"
        :disabled="companyPaymentInfoLoadingCreateWithCreditCard"
      >
        Pagar por Boleto/Pix
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      class="text-caption"
      style="text-align: justify; text-justify: inter-word"
    >
      <p
        class="
          custom-normal-text
          font-weight-bold
          grey--text
          text--darken-1
          mb-1
        "
        >Importante</p
      >
      <ul class="custom-suntitle-text grey--text text--darken-1">
        <li>
          Caso o seu computador tenha um programa anti pop-up, será preciso
          desativá-lo antes de finalizar sua compra e imprimir o boleto ou pagar
          pelo internet banking;
        </li>
        <li>
          Não faça depósito ou transferência entre contas. O boleto não é
          enviado pelos Correios. Imprima-o e pague-o no banco ou pela internet;
        </li>
        <li>
          Se o boleto não for pago até a data de vencimento, o pedido será
          automaticamente cancelado;
        </li>
        <li>
          Serviços contrata pagos com boleto bancário começa a contar 48hs
          depois do pagamento do boleto, tempo necessário para que a instituição
          bancária confirmar o pagamento.
        </li>
      </ul>
    </v-col>
  </v-row>
</template>

<script>
import { TYPE_BOLETO } from '@state/modules/payment-info'
import {
  paymentComputed,
  paymentMethods,
  authComputed,
  companyComputed,
} from '@state/helpers'

import salesPartner from '@src/services/sales-partner'
import imgBoleto from '@src/assets/images/misc/icon-pag-boleto.png'

export default {
  name: 'payment-boleto',
  data() {
    return {
      imgBoleto,
    }
  },
  computed: {
    ...paymentComputed,
    ...authComputed,
    ...companyComputed,
  },
  methods: {
    ...paymentMethods,
    paymentCreat() {
      this.createPayment({
        idPaymentsType: TYPE_BOLETO,
        idParceiro: salesPartner.getId(),
      }).then((res) => {
        this.$ga.event({
          eventCategory: this.company.isOppening ? 'Abertura' : 'Migração',
          eventAction: 'pagamento',
          eventLabel: 'dados_pagamento_boleto',
          eventValue: 8,
        })
        this.$ga.ecommerce.addItem({
          id: this.currentUser.id, // Transaction ID. Required.
          name: this.company.inputType.name, // Product name. Required.
          category: `${this.company.inputType.name} - Boleto`, // Category or variation.
          quantity: '1', // Quantity.
          price: this.firstPaymentSummary.total / 100,
        })
        this.$ga.ecommerce.addTransaction({
          id: this.currentUser.id, // Transaction ID. Required.
          affiliation: 'Simplificador', // Affiliation or store name.
          revenue: this.firstPaymentSummary.total / 100,
        })
        this.$ga.ecommerce.send()
        this.$GTAG({
          event: 'purchase-boleto',
          value: this.firstPaymentSummary.total / 100,
          id: this.currentUser.id,
        })

        this.$GTAG('event', 'purchase', {
          sendTo: 'AW-1006960147/dIMGCIaKg5QBEJP8k-AD',
          value: this.firstPaymentSummary.total / 100,
          id: this.currentUser.id,
        })
      })
    },
  },
}
</script>
<style module lang="scss">
@import '@design';
</style>
