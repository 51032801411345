<template>
  <div>
    <v-col
      cols="12"
      class="text-center text-body-2 grey--text text--lighten-1 mt-0 pt-0"
    >
      compra segura
      <v-icon small color="grey lighten-1">lock</v-icon>
    </v-col>
    <v-col
      cols="12"
      class="custom-normal-text text-green font-weight-bold px-0"
    >
      Formas de pagamento
    </v-col>

    <v-row class="mb-2">
      <v-col cols="12" sm="4">
        <v-btn
          :class="[
            'rounded-0',
            btnActive.bankSlip ? 'custom-btn-blue' : 'custom-btn-blue-inverse',
          ]"
          block
          @click=";(btnActive.bankSlip = true), (btnActive.creditCard = false)"
        >
          <v-icon left>$vuetify.icons.bankSlip</v-icon>
          boleto/pix
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4">
        <v-btn
          :class="[
            'rounded-0',
            btnActive.creditCard
              ? 'custom-btn-blue'
              : 'custom-btn-blue-inverse',
          ]"
          block
          @click=";(btnActive.bankSlip = false), (btnActive.creditCard = true)"
        >
          <v-icon left>credit_card</v-icon>
          cartão de crédito
        </v-btn>
      </v-col>
    </v-row>

    <bank-slip
      transition="slide-x-reverse-transition"
      v-if="btnActive.bankSlip"
    ></bank-slip>
    <credit-card
      transition="slide-x-reverse-transition"
      v-if="btnActive.creditCard"
    ></credit-card>
    <v-alert type="error" outlined :value="paymentError">
      {{ paymentErrorMessage }}
    </v-alert>
  </div>
</template>

<script>
import bankSlip from './Boleto.vue'
import creditCard from './Creditcard.vue'
import { paymentComputed } from '@state/helpers'
export default {
  components: {
    bankSlip,
    creditCard,
  },
  data() {
    return {
      btnActive: {
        bankSlip: false,
        creditCard: true,
      },
    }
  },
  computed: {
    ...paymentComputed,
  },
}
</script>

<style module lang="scss">
@import '@design';
</style>
