<template>
  <form @submit="paymentByCreditcard" v-on:submit.prevent ref="form">
    <v-row no-gutters>
      <v-col cols="12" sm="9">
        <v-text-field
          ref="creditcard"
          autocomplete="off"
          label="Número do Cartão"
          v-model="form.creditcard"
          :error="!error.creditcard.valid"
          :error-messages="
            !error.creditcard.valid ? error.creditcard.errorMassang : ''
          "
          validate-on-blur
          @input="error.creditcard.valid = true"
          v-mask="'#### - #### - #### - ####'"
          :v-masked="true"
        ></v-text-field>
        <icons-card :active="brand"></icons-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="9">
        <v-text-field
          ref="name"
          autocomplete="off"
          label="Nome impresso no cartão"
          v-model="form.name"
          :error="!error.name.valid"
          :error-messages="!error.name.valid ? error.name.errorMassang : ''"
          @input="error.name.valid = true"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="3" class="mr-2">
        <v-text-field
          ref="expMonth"
          autocomplete="off"
          label="Validade - Mês"
          v-mask="'##'"
          v-model="form.expMonth"
          :error="!error.expMonth.valid"
          :error-messages="
            !error.expMonth.valid ? error.expMonth.errorMassang : ''
          "
          @input="error.expMonth.valid = true"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          ref="expYear"
          autocomplete="off"
          label="Validade - Ano"
          v-mask="'##'"
          v-model="form.expYear"
          validate-on-blur
          :error="!error.expYear.valid"
          :error-messages="
            !error.expYear.valid ? error.expYear.errorMassang : ''
          "
          @input="error.expYear.valid = true"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="4">
        <v-text-field
          ref="cvv"
          autocomplete="off"
          label="CVV"
          v-model="form.cvv"
          :error="!error.cvv.valid"
          :error-messages="!error.cvv.valid ? error.cvv.errorMassang : ''"
          pattern="[0-9]{3,4}"
          v-mask="'####'"
          @input="error.cvv.valid = true"
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="mb-3">
      <strong>
        Total:
        {{ firstPaymentSummary.total | currencyFromCents }}
      </strong>
    </div>

    <v-btn
      class="ml-0 custom-btn-green rounded-0"
      large
      type="submit"
      :loading="companyPaymentInfoLoadingCreateWithCreditCard"
      :readonly="companyPaymentInfoLoadingCreate"
      :disabled="companyPaymentInfoLoadingCreateWithBoleto"
    >
      pagar por cartão de crédito
    </v-btn>
  </form>
</template>
<script>
import { TYPE_CREDITCARD } from '@state/modules/payment-info'
import creditcard from '@src/services/creditcard'
import iconsCard from '@components/widget/iconsCard.vue'
import {
  paymentComputed,
  paymentMethods,
  authComputed,
  companyComputed,
} from '@state/helpers'

export default {
  name: 'payment-creditcard',
  components: {
    iconsCard,
  },
  data() {
    return {
      error: {
        name: {
          valid: true,
          errorMassang: 'Preencha corretamente',
        },
        creditcard: {
          valid: true,
          errorMassang: 'Preencha corretamente',
        },
        cvv: {
          valid: true,
          errorMassang: 'Preencha corretamente',
        },
        expMonth: {
          valid: true,
          errorMassang: 'Preencha corretamente',
        },
        expYear: {
          valid: true,
          errorMassang: 'Preencha corretamente',
        },
      },
      form: {
        name: '',
        creditcard: '',
        cvv: '',
        expMonth: '',
        expYear: '',
        firstName: '',
        lastName: '',
      },
    }
  },
  computed: {
    ...paymentComputed,
    ...authComputed,
    ...companyComputed,
    brand() {
      if (this.form.creditcard.length > 4) {
        return this.getBrandByCreditCardNumber(this.form.creditcard)
      }
      return null
    },
    firstName() {
      return this.form.name.split(' ')[0]
    },
    lastName() {
      if (!this.form.name) return ''
      let match = /^(\w*)([\s*\w*]*)/.exec(this.form.name)
      return match[match.length - 1].trimStart()
    },
  },
  methods: {
    ...paymentMethods,
    ...creditcard,
    creditcardIsValid() {
      this.error.creditcard.valid = this.validateNumber(this.form.creditcard)
      this.error.cvv.valid = this.validateCvv(
        this.form.creditcard,
        this.form.cvv
      )
      this.error.expYear.valid = this.validateExpirationByMonthAndYear({
        month: this.form.expMonth,
        year: this.form.expYear,
      })
      this.error.expMonth.valid = this.validateExpirationByMonthAndYear({
        month: this.form.expMonth,
        year: this.form.expYear,
      })
      this.error.name.valid = !!this.form.name

      return (
        this.error.creditcard.valid &&
        this.error.cvv.valid &&
        this.error.expYear.valid &&
        this.error.expMonth.valid &&
        this.error.name.valid
      )
    },
    setErrorCardAll() {
      this.error.creditcard.valid = false
      this.error.cvv.valid = false
      this.error.expYear.valid = false
      this.error.expMonth.valid = false
      this.error.name.valid = false
    },
    async paymentByCreditcard(event) {
      event.preventDefault()

      if (this.creditcardIsValid()) {
        const {
          creditcard: number,
          expMonth: month,
          expYear: year,
          cvv,
        } = this.form
        const iuguCreditCard = this.objectCreditCard({
          number,
          month,
          year,
          firstName: this.firstName,
          lastName: this.lastName,
          cvv,
        })
        let iuguToken = await this.createCreditcardToken(iuguCreditCard)
        if (iuguToken.errors) {
          this.setErrorCardAll()
        } else {
          this.createPayment({
            idPaymentsType: TYPE_CREDITCARD,
            creditCardTokenIugu: iuguToken.id,
            creditCardLastNumbers:
              iuguToken.extra_info.display_number.split('-')[3],
          }).then((result) => {
            this.$vuetify.goTo(0, {
              duration: 3000,
              offset: 0,
              easing: 'linear',
            })
            this.$ga.event({
              eventCategory: this.company.isOppening ? 'Abertura' : 'Migração',
              eventAction: 'pagamento',
              eventLabel: 'dados_pagamento_cartao',
              eventValue: 9,
            })
            this.$ga.ecommerce.addItem({
              id: this.currentUser.id, // Transaction ID. Required.
              name: this.company.inputType.name, // Product name. Required.
              category: `${this.company.inputType.name} - Cartão`, // Category or variation.
              quantity: '1', // Quantity.
              price: this.firstPaymentSummary.total / 100,
            })
            this.$ga.ecommerce.addTransaction({
              id: this.currentUser.id, // Transaction ID. Required.
              affiliation: 'Simplificador', // Affiliation or store name.
              revenue: this.firstPaymentSummary.total / 100,
            })
            this.$ga.ecommerce.send()
            this.$GTAG({
              event: 'purchase-credit-card',
              value: this.firstPaymentSummary.total / 100,
              id: this.currentUser.id,
            })
            this.$GTAG('event', 'purchase', {
              sendTo: 'AW-1006960147/dIMGCIaKg5QBEJP8k-AD',
              value: this.firstPaymentSummary.total / 100,
              id: this.currentUser.id,
            })
          })
        }
      }
    },
  },
}
</script>
<style module lang="scss">
@import '@design';
</style>
