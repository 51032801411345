<template>
  <div class="mb-6" v-if="!loading">
    <info-responsible-payment class="mb-5"></info-responsible-payment>

    <template v-if="!checkIfPlanIsFree">
      <cupom></cupom>
      <v-divider class="my-6"></v-divider>
      <payment-method />
    </template>
    <free-plan v-else />
  </div>
</template>

<script>
import paymentMethod from './paymentMethod.vue'
import infoResponsiblePayment from './infoResponsiblePayment.vue'
import cupom from './cupom.vue'
import freePlan from './freePlan.vue'
import { paymentComputed, paymentMethods } from '@state/helpers'

export default {
  components: {
    infoResponsiblePayment,
    cupom,
    paymentMethod,
    freePlan,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...paymentComputed,
  },
  methods: {
    ...paymentMethods,
  },
  async created() {
    this.loading = true
    await this.getPaymentInfoByRegistering()
    await this.getFirstPaymentSummary()
    this.loading = false
  },
}
</script>

<style lang="scss" scoped></style>
